.playlist-generator,
.mood-selection, .playlist-display {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background-color: #07120D;
  box-shadow: 
    0 0 200px 100px rgba(62, 228, 121, 0.3),
    0 0 300px 20px rgba(62, 228, 121, 0.2),
    0 0 150px rgba(0, 0, 0, 0.9);
  padding-bottom: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* HEADER */
.playlist-generator h3, .mood-selection h3 {
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  margin-left: 20px;
  padding-left: 10px;
}

/* SEARCH INPUT */
.search-container {
  margin: 20px 0;
}

.search-input {
  width: 80%;
  height: 60px;
  border-radius: 30px;
  background-color: #575757;
  font-size: 16px;
  border: none;
  outline: none;
  padding: 0 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.search-input::placeholder {
  color: #a0a0a0;
}

/* ARTISTS CONTAINER */
.artists-container,
.moods-container,
.tracks-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
  margin: 20px 20px 0 20px;
  padding: 10px;
  height: calc(100% - 200px);
  margin-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  justify-items: center;
  align-items: start;
  box-sizing: border-box;
}

.artist-circle {
  text-align: center;
  width: 100px;
  min-height: 120px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0px;
}

.artist-circle.selected img, .placeholder-image.selected {
    border: 2px solid #1DB954;
}

.artist-circle.selected::before, .placeholder-image::before {
    content: '';
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(29, 185, 84, 0.3);
    box-shadow: 
      0 0 20px rgba(29, 185, 84, 0.3),
      0 0 8px rgba(29, 185, 84, 0.2);
    filter: blur(3px);
    z-index: -100;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.artist-circle img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 10px #000000;
  flex-shrink: 0;
}

.placeholder-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #000000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
}

.artist-circle p {
  margin-top: 8px !important;
  font-size: 14px !important;
  font-weight: 800;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  padding: 0 5px;
  box-sizing: border-box;
}

.bottom-container {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #07120D;
  border-radius: 0 0 40px 40px;
  margin-top: auto;
  height: 50px;
  z-index: 10;
}

.submit-button {
  margin: 0 auto;
  padding: 12px 30px;
  background-color: #1DB954;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 30%;
}

.submit-button:hover {
  background-color: #15833C;
  transform: scale(1.05);
}



/* MOOD SELECTION */
.mood-selection { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background-color: #07120D;
  padding-bottom: 0;
  z-index: 10;
}

.mood-selection h3 {
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  margin-left: 20px;
  padding-left: 10px;
}


.moods-container {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    overflow-y: auto !important;
    justify-content: center;
    align-items: start;
    box-sizing: border-box;
}

.mood-item {
    background-color: #282828;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    padding: 12px 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    box-sizing: border-box;
}

.mood-item:hover {
    transform: scale(1.05);
    background-color: #383838;
}

.mood-item.selected {
  background-color: #1DB954 !important;
  color: white;
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(29, 185, 84, 0.3);
}



/* GENERATED PLAYLIST */
.tracks-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  margin: 20px 20px 0 20px;
  padding: 10px;
  height: calc(100% - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  justify-items: center;
  align-items: start;
  box-sizing: border-box;
  padding-bottom: 60px;
}

.playlist-display h3 {
  margin-top: 20px;
  margin-left: 40px;
}

.track-item {
  width: 100%;
  max-width: 200px;
  margin: 0;
  background: #282828;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  animation: fadeInUp 0.5s ease forwards;
  height: fit-content;
}

.track-item:nth-child(1) { animation-delay: 0.1s; }
.track-item:nth-child(2) { animation-delay: 0.2s; }
.track-item:nth-child(3) { animation-delay: 0.3s; }
.track-item:nth-child(4) { animation-delay: 0.4s; }
.track-item:nth-child(5) { animation-delay: 0.5s; }
.track-item:nth-child(6) { animation-delay: 0.6s; }
.track-item:nth-child(7) { animation-delay: 0.7s; }
.track-item:nth-child(8) { animation-delay: 0.8s; }
.track-item:nth-child(9) { animation-delay: 0.9s; }
.track-item:nth-child(10) { animation-delay: 1.0s; }
.track-item:nth-child(11) { animation-delay: 1.1s; }
.track-item:nth-child(12) { animation-delay: 1.2s; }
.track-item:nth-child(13) { animation-delay: 1.3s; }
.track-item:nth-child(14) { animation-delay: 1.4s; }
.track-item:nth-child(15) { animation-delay: 1.5s; }
.track-item:nth-child(16) { animation-delay: 1.6s; }
.track-item:nth-child(17) { animation-delay: 1.7s; }
.track-item:nth-child(18) { animation-delay: 1.8s; }
.track-item:nth-child(19) { animation-delay: 1.9s; }
.track-item:nth-child(20) { animation-delay: 2.0s; }
.track-item:nth-child(21) { animation-delay: 2.1s; }
.track-item:nth-child(22) { animation-delay: 2.2s; }
.track-item:nth-child(23) { animation-delay: 2.3s; }
.track-item:nth-child(24) { animation-delay: 2.4s; }
.track-item:nth-child(25) { animation-delay: 2.5s; }
.track-item:nth-child(26) { animation-delay: 2.6s; }
.track-item:nth-child(27) { animation-delay: 2.7s; }
.track-item:nth-child(28) { animation-delay: 2.8s; }
.track-item:nth-child(29) { animation-delay: 2.9s; }
.track-item:nth-child(30) { animation-delay: 3.0s; }
.track-item:nth-child(31) { animation-delay: 3.1s; }
.track-item:nth-child(32) { animation-delay: 3.2s; }
.track-item:nth-child(33) { animation-delay: 3.3s; }
.track-item:nth-child(34) { animation-delay: 3.4s; }
.track-item:nth-child(35) { animation-delay: 3.5s; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.track-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.track-image {
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
}

.track-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.track-info {
  padding: 12px;
}

.track-name {
  font-weight: bold;
  margin-bottom: 4px;
  color: white;
}

.track-artist {
  color: #b3b3b3;
  font-size: 0.9em;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  background: #404040;
  align-items: center;
  justify-content: center;
}

.playlist-display {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.playlist-blur {
  animation: blurEffect 2s ease forwards;
  animation-delay: 5s;
}


.playlist-blur .tracks-container,
.playlist-blur .back-button,
.playlist-blur h3 {
  animation: blurEffect 2s ease forwards;
  animation-delay: 6s;
}

.download-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000 !important;
  opacity: 0;
  animation: fadeInOverlay 1s ease forwards;
  animation-delay: 5s;
}

.download-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 1rem;
}

.download-overlay h2 {
  color: white;
  font-size: 42px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0;
  animation: fadeInLabel 1s ease forwards;
  animation-delay: 5s;
}

.download-overlay p {
  font-size: 28px !important;
  color: white;
  font-weight: bold !important;
  opacity: 0;
  animation: fadeInLabel 1s ease forwards;
  animation-delay: 5.5s;
}

.download-content .download-button {
  margin-top: 0px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.playlist-blur {
  position: relative;
}

@keyframes blurEffect {
  from {
    filter: blur(0);
  }
  to {
    filter: blur(8px);
  }
}

@keyframes fadeInLabel {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInOverlay {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}



@media screen and (max-width: 768px) {
  .playlist-generator, .mood-selection, .playlist-display {
    padding: 20px 10px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .playlist-display {
    box-shadow: none;
  }

  .playlist-generator h3 {
    font-size: 22px;
    padding-left: 0px;
  }

  .search-input {
    height: 30px;
    width: 90%;
    font-size: 16px;
    padding: 8px;
    padding-left: 20px;
  }

  .artists-container,
  .moods-container, .tracks-container {
    height: calc(100% - 200px);
  }

  .artists-container {
    grid-template-columns: repeat(auto-fit, minmax(85px, 1fr));
    gap: 10px;
    padding: 5px;
  }

  .artist-circle {
    width: 85px;
    min-height: 105px;
  }

  .artist-circle img {
    width: 70px;
    height: 70px;
  }

  .artist-circle p {
    font-size: 12px !important;
  }

  .moods-container {
    grid-template-columns: repeat(auto-fit, minmax(85px, 1fr));
    gap: 10px;
    padding: 5px;
  }

  .mood-item {
    padding: 8px 12px;
    font-size: 14px;
    margin: 10px;
  }

  .tracks-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    margin: 10px;
    padding: 5px;
    margin: 20px 20px 0 20px;
  }

  .track-item {
    padding: 8px;
  }

  .track-image {
    width: 50%;
    height: 50%;
  }

  .track-info {
    margin-left: 10px;
  }

  .track-name {
    font-size: 14px;
  }

  .track-artist {
    font-size: 12px;
  }

  .download-content {
    width: 90%;
    padding: 20px;
  }

  .bottom-container {
    margin-bottom: 10px;
  }

  .submit-button {
    width: 50%;
    padding: 12px;
    font-size: 16px;
  }

  .back-button {
    font-size: 24px;
    padding: 8px;
  }
}
