/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
}

body, html {
  margin: 0;
  padding: 0;
  background-color: #000000;
  min-height: 100vh;
  font-family: 'DM Sans', sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.home-page,
.info,
.test-section,
.features,
.download-section,
.faq-section,
.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
}

.info,
.test-section,
.features,
.download-section,
.faq-section,
.contact-section {
  margin-top: 450px !important;
}

h2 {
  margin-bottom: 20px;
}

@media screen and (min-width: 425px) and (max-width: 768px) {
  .home-page {
    margin-top: 40vh;
  }
}

/* ==================== HOME SECTION ==================== */
.home-page {
  flex-direction: row;
  justify-content: space-between;
  color: #ffffff;
  max-height: 150vh;
  scroll-margin-top: 50vh;
}

.content-left {
  width: 50%;
  margin-left: 4%;
}

.content-left p {
  display: none;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
}

.content-left h1 {
  font-size: 58px;
  font-weight: bold;
  opacity: 0;
  animation: slideDown 1s ease forwards;
}

.content-left .description {
  align-items: left;
  line-height: 1.4;
}

.content-left .description p {
  font-size: 32px;
  font-weight: normal;
  text-align: left;
  opacity: 0;
  animation: slideDown 1s ease forwards;
  animation-delay: 0.3s;
}

.content-left .generate-button {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  background-color: #1DCB5A;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  padding: 15px 30px;
  align-items: center;
  transition: all 0.3s ease;
  box-shadow: 0 0 50px rgba(62, 228, 121, 0.5);
  opacity: 0;
  animation: fadeIn 1.5s ease forwards;
  animation-delay: 0.6s;
}

.generate-button:hover {
  background-color: #1ed760;
  transform: scale(1.05);
}

.content-right {
  margin-top: 7%;
  margin-right: 0%;
  width: 45%;
  align-items: right;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  animation-delay: 0.9s;
}

.playlist_generation_view {
  width: 80%;
  height: auto;
  border-radius: 100px;
  box-shadow: 0 4px 300px rgba(62, 228, 121, 0.5);
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  animation-delay: 0.9s;
}

/* HOME PAGE MEDIA ADJUSTMENTS */
@media (max-width: 768px) {
  .home-page {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  
  .content-left {
    width: 100%;
    margin: 0 auto;
    margin-top: 35vh;
    text-align: center;
    margin-bottom: 100px;
  }

  .content-left p {
    display: block;
  }

  .content-left h1 {
    text-align: center;
  }

  .content-left .description p {
    text-align: center;
  }

  .content-right {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .playlist_generation_view {
    width: 80%;
    border-radius: clamp(50px, 8vw, 100px);
  }
}



/* ==================== INFO SECTION ==================== */
.info {
  text-align: center;
  position: relative;

  color: #ffffff;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

.info .blur-circle {
  position: absolute;
  width: 630px;
  height: 630px;
  background: #3EE479;
  border-radius: 50%;
  filter: blur(250px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  opacity: 0.5;
}

.info h2 {
  font-size: 58px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
  opacity: 0;
  transform: translateY(-100px);
  transition: all 1s ease;
}

.info h2.visible {
  opacity: 1;
  transform: translateY(0);
}

.info .description p {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.4;
  width: 80%;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease;
}

.info .description p.visible {
  opacity: 1;
  transform: translateY(0);
}

.info h2,
.info .description {
  position: relative;
  z-index: 1;
}



/* ==================== TEST SECTION ==================== */
.test-section {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.test-section h2 {
  font-size: 58px;
  font-weight: 700;
  text-align: center;
  position: relative;
  z-index: 1;
}

.test-section .description p {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
}

.test-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70vw;
  height: 600px;
  background-color: #1DCB5A;
  border-radius: 50px;
  box-shadow: 0 50px 30px rgba(0, 0, 0, 0.9);
  padding: auto;
  z-index: 0;
}

@media (max-width: 768px) {
  .test-container {
    width: 90vw;
    height: 90vh;
  }
}



/* ==================== FEATURES SECTION ==================== */
.features {
  width: 100%;
  color: #ffffff;
  padding: 20px;
}

.features h2 {
  font-size: 58px;
  font-weight: 700;
  text-align: center;
}

.features .description p {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  text-wrap: balance;
  margin-bottom: 60px;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 0 40px;
}

.feature-item {
  border-radius: 40px;
  background: rgba(7, 18, 13, 0.8);
  padding: 20px;
  text-align: left;
  box-shadow: 
    0 0 30px rgba(62, 228, 121, 0.2),
    0 0 60px rgba(62, 228, 121, 0.1);
  transition: all 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 0 80px rgba(62, 228, 121, 0.3),
    0 0 120px rgba(62, 228, 121, 0.2);
}

.feature-item h3 {
  color: #1DB954;
  font-size: 1.9rem;
  margin-bottom: 15px;
}

.feature-item p {
  font-size: 1.4rem;
  line-height: 1.6;
  color: #ffffff;
  opacity: 0.9;
}

/* FEATURES MEDIA ADJUSTMENTS */
@media (max-width: 768px) {
  .features-container {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 0 1rem;
  }

  .feature-item {
    text-align: center;
    box-shadow: 
    0 0 70px rgba(62, 228, 121, 0.3),
    0 0 100px rgba(62, 228, 121, 0.1);
  }
}


/* ==================== DOWNLOAD SECTION ==================== */
.download-section {
  position: relative;
  color: #ffffff;
  width: 100%;
  z-index: 1;
}

.download-section h2 {
  font-size: 58px;
  font-weight: bold;
  text-align: center;
}

.download-section .description p {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.download-section .blur-circle {
  position: absolute;
  width: 630px;
  height: 630px;
  background: #3EE479;
  border-radius: 50%;
  filter: blur(250px);
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  pointer-events: none;
}

.download-section h2,
.download-section .description {
  position: relative;
  z-index: 1;
}

.download-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-button {
  position: relative;
  z-index: 2;
  margin-top: 50px;
  padding: 20px 40px;
  font-size: 20px;
  font-weight: 800;
  color: #ffffff;
  background-color: #1DCB5A;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
  transition: all 0.3s ease;
}

.download-button:hover {
  background-color: #1ed760;
}



/* ==================== FAQ SECTION ==================== */
.faq-section {
  color: #ffffff;
}

.faq-section h2 {
  font-size: 58px;
  font-weight: 700;
  text-align: center;
}

.faq-container {
  margin: 0 auto;
  width: 75%;
}

.faq-item {
  margin: 50px 0;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0 10px 50px rgba(62, 228, 121, 0.3);
  text-align: center;
}

.faq-question {
  width: 100%;
  padding: 15px;
  text-align: center;
  background: none;
  border: none;
  cursor: pointer;

  font-size: 1.4rem;
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  color: white;
}

.faq-answer {
  padding: 20px;
  font-size: 1.1rem;
  text-align: center;
  color: #1DCB5A;
}

@media (max-width: 768px) {
  .faq-container {
    width: 90%;
  }

  .faq-item {
    margin: 40px 0;
    padding: 2px;
  }

  .faq-question {
    padding: 15px 10px 15px 10px;
  }

}



/* ==================== CONTACT SECTION ==================== */
.contact-section .blur-circle {
  position: absolute;
  width: 630px;
  height: 630px;
  background: #3EE479;
  border-radius: 50%;
  filter: blur(250px);
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  will-change: transform;
  pointer-events: none;
}

.contact-section {
  position: relative;
  text-align: center;
  color: #ffffff;
}

.contact-section h2 {
  font-size: 58px;
  font-weight: 700;
}

.contact-section .description {
  font-size: 32px;
  font-weight: normal;
  text-align: center;
  width: 70%;
  margin-top: -20px;
}

.contact-section h2,
.contact-section .description {
  position: relative;
  z-index: 1;
}

.description p {
  text-align: center;
}

.contact-email {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
}

.contact-email a {
  color: #1DCB5A;
  text-align: center;
}



/* ==================== FOOTER ==================== */
.footer {
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 14px;
}



/* ANIMATIONS */
@keyframes slideDown {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

/* Add these base styles for all section headings and descriptions */
.info h2,
.test-section h2,
.features h2,
.download-section h2,
.faq-section h2,
.contact-section h2 {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 1s ease;
}

.info .description p,
.test-section .description p,
.features .description p,
.download-section .description p,
.faq-section .description p,
.contact-section .description p {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease;
}

/* Common visible state for all sections */
.info h2.visible,
.test-section h2.visible,
.features h2.visible,
.download-section h2.visible,
.faq-section h2.visible,
.contact-section h2.visible,
.info .description p.visible,
.test-section .description p.visible,
.features .description p.visible,
.download-section .description p.visible,
.faq-section .description p.visible,
.contact-section .description p.visible {
  opacity: 1;
  transform: translateY(0);
}

.contact-section .contact-email {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease;
}

.contact-section .contact-email.visible {
  opacity: 1;
  transform: translateY(0);
}
