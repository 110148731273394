.body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

/* Tab Bar Container */
.tabBarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  margin-top: 5%;
}

/* Logo */
.logo {
  position: fixed;
  left: 40px;
  color: #ffffff;
  font-size: 32px;
  font-weight: 700;
}

/* Tab Bar */
.tabBar {
  position: fixed;
  background-color: rgba(77, 77, 77, 0.5);
  border-radius: 30px;
  padding: 10px 40px;
  backdrop-filter: blur(10px);
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0 5px 100px rgba(16, 53, 28, 0.7);
}

.tabList {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

/* Tab */
.tabList a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 10px 20px;
}

.tabList a:hover {
  color: #006e27;
}



/* Mobile Adjustments */
@media (max-width: 768px) {
  .tabBarContainer {
    margin-top: 5%;
    flex-direction: column;
  }

  .tabBar {
    min-width: 65%;
    max-width: 85%;
    margin: 0 auto;
    padding: 5px 5px;
  }
  
  .logo {
    position: relative;
    opacity: 0;
    left: auto;
    transform: none;
    top: 6.5vh;
    text-align: center;
  }
  
  .tabList {
    gap: clamp(2px, 3vw, 20px);
    width: 100%;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 30px;
  }
  
  .tabList a {
    font-size: clamp(12px, 2vw, 18px);
    padding: 7px 7px;
    white-space: nowrap;
    text-align: center;
  }
}